import React from "react"
import { graphql } from "gatsby"
import PicturePage from "../../components/PicturePage"

const AlbumPage = ({ data }) => (
  <PicturePage data={data} text="Learning Unit VI" />
)

export default AlbumPage

export const query = graphql`
  query LU6Photos {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/src/images/LU6/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 750) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
